[data-rmiz-ghost] {
  position: absolute;
  pointer-events: none;
}
[data-rmiz-btn-zoom],
[data-rmiz-btn-unzoom] {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  color: #fff;
  height: 40px;
  margin: 0;
  outline-offset: 2px;
  padding: 9px;
  touch-action: manipulation;
  width: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
[data-rmiz-btn-zoom]:not(:focus):not(:active) {
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  pointer-events: none;
  white-space: nowrap;
  width: 1px;
}
[data-rmiz-btn-zoom] {
  position: absolute;
  inset: 10px 10px auto auto;
  cursor: zoom-in;
}
[data-rmiz-btn-unzoom] {
  position: absolute;
  inset: 20px 20px auto auto;
  cursor: zoom-out;
  z-index: 1;
}
[data-rmiz-content="found"] img,
[data-rmiz-content="found"] svg,
[data-rmiz-content="found"] [role="img"],
[data-rmiz-content="found"] [data-zoom] {
  cursor: zoom-in;
}
[data-rmiz-modal]::backdrop {
  display: none;
}
[data-rmiz-modal][open] {
  position: fixed;
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  max-width: none;
  max-height: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  overflow: hidden;
}
[data-rmiz-modal-overlay] {
  position: absolute;
  inset: 0;
  transition: background-color 0.3s;
}
[data-rmiz-modal-overlay="hidden"] {
  background-color: rgba(255, 255, 255, 0);
}
[data-rmiz-modal-overlay="visible"] {
  background-color: rgba(255, 255, 255, 1);
}
[data-rmiz-modal-content] {
  position: relative;
  width: 100%;
  height: 100%;
}
[data-rmiz-modal-img] {
  position: absolute;
  cursor: zoom-out;
  image-rendering: high-quality;
  transform-origin: top left;
  transition: transform 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  [data-rmiz-modal-overlay],
  [data-rmiz-modal-img] {
    transition-duration: 0.01ms !important;
  }
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

html{
  height: 100%;
  scroll-behavior: smooth;
}

body{
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity, 1));
  height: 100%;
  font-size: 14px;
  overflow: hidden;
}

h1{
  font-size: 20px;
  font-weight: 500;
}

h2 {
  font-size: 14px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* PDF Rendering */
.previous-document {
  canvas{
    opacity: 0.2;
  }
}

/* Medium Zoom */
[data-rmiz-modal-overlay='hidden'] {
  background-color: rgb(28, 25, 23, 0);
}
[data-rmiz-modal-overlay='visible'] {
  background-color: rgb(28, 25, 23, 1);
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}.__variable_d65c78 {--font-inter: 'Inter', 'Inter Fallback'
}

:root {
  --spectrum-red-dark: hsl(0 100% 40%);
  --spectrum-red-desaturated: hsl(0 100% 40% / 0.12);
  --spectrum-red-light: hsl(0 100% 70%);
  --spectrum-red-saturated: hsl(0 100% 56%);

  --spectrum-orange-dark: hsl(15 100% 40%);
  --spectrum-orange-desaturated: hsl(15 100% 50% / 0.12);
  --spectrum-orange-light: hsl(15 100% 70%);
  --spectrum-orange-saturated: hsl(15 100% 55%);

  --spectrum-amber-dark: hsl(30 100% 40%);
  --spectrum-amber-desaturated: hsl(30 100% 50% / 0.12);
  --spectrum-amber-light: hsl(30 100% 70%);
  --spectrum-amber-saturated: hsl(30 100% 52%);

  --spectrum-yellow-dark: hsl(45 100% 35%);
  --spectrum-yellow-desaturated: hsl(45 100% 50% / 0.16);
  --spectrum-yellow-light: hsl(45 100% 70%);
  --spectrum-yellow-saturated: hsl(45 100% 54%);

  --spectrum-lime-dark: hsl(90 80% 35%);
  --spectrum-lime-desaturated: hsl(91 96% 46% / 0.16);
  --spectrum-lime-light: hsl(90 80% 70%);
  --spectrum-lime-saturated: hsl(90 83% 56%);

  --spectrum-green-dark: hsl(120 48% 40%);
  --spectrum-green-desaturated: hsl(120 94% 34% / 0.12);
  --spectrum-green-light: hsl(120 48% 70%);
  --spectrum-green-saturated: hsl(120 59% 51%);

  --spectrum-emerald-dark: hsl(150 48% 40%);
  --spectrum-emerald-desaturated: hsl(149 94% 34% / 0.12);
  --spectrum-emerald-light: hsl(150 48% 70%);
  --spectrum-emerald-saturated: hsl(150 79% 52%);

  --spectrum-teal-dark: hsl(180 80% 32%);
  --spectrum-teal-desaturated: hsl(180 96% 46% / 0.12);
  --spectrum-teal-light: hsl(180 80% 60%);
  --spectrum-teal-saturated: hsl(180 83% 46%);

  --spectrum-cyan-dark: hsl(195 96% 40%);
  --spectrum-cyan-desaturated: hsl(195 97% 50% / 0.12);
  --spectrum-cyan-light: hsl(195 96% 70%);
  --spectrum-cyan-saturated: hsl(195 96% 49%);

  --spectrum-sky-dark: hsl(210 97% 40%);
  --spectrum-sky-desaturated: hsl(210 100% 50% / 0.12);
  --spectrum-sky-light: hsl(210 97% 75%);
  --spectrum-sky-saturated: hsl(210 100% 57%);

  --spectrum-blue-dark: hsl(225 96% 40%);
  --spectrum-blue-desaturated: hsl(225 97% 50% / 0.16);
  --spectrum-blue-light: hsl(225 96% 80%);
  --spectrum-blue-saturated: hsl(225 100% 56%);

  --spectrum-indigo-dark: hsl(249 98% 40%);
  --spectrum-indigo-desaturated: hsl(248 100% 51% / 0.16);
  --spectrum-indigo-light: hsl(249 98% 80%);
  --spectrum-indigo-saturated: hsl(249 100% 63%);

  --spectrum-violet-dark: hsl(270 80% 40%);
  --spectrum-violet-desaturated: hsl(269 96% 46% / 0.16);
  --spectrum-violet-light: hsl(270 80% 75%);
  --spectrum-violet-saturated: hsl(270 91% 53%);

  --spectrum-purple-dark: hsl(285 80% 40%);
  --spectrum-purple-desaturated: hsl(275 100% 50% / 0.12);
  --spectrum-purple-light: hsl(285 80% 70%);
  --spectrum-purple-saturated: hsl(285 91% 53%);

  --spectrum-fuchsia-dark: hsl(315 89% 40%);
  --spectrum-fuchsia-desaturated: hsl(315 100% 52% / 0.12);
  --spectrum-fuchsia-light: hsl(315 89% 70%);
  --spectrum-fuchsia-saturated: hsl(315 92% 51%);

  --spectrum-pink-dark: hsl(330 80% 40%);
  --spectrum-pink-desaturated: hsl(315 96% 46% / 0.12);
  --spectrum-pink-light: hsl(330 80% 70%);
  --spectrum-pink-saturated: hsl(330 91% 53%);
}

