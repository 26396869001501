@import 'react-medium-image-zoom/dist/styles.css';

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

html {
  @apply h-full;
  scroll-behavior: smooth;
}

body {
  @apply bg-background text-text;
  @apply h-full;
  font-size: 14px;
  overflow: hidden;
}

h1 {
  @apply text-2xl;
  font-weight: 500;
}

h2 {
  font-size: 14px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* PDF Rendering */
.previous-document {
  canvas {
    @apply opacity-20;
  }
}

/* Medium Zoom */
[data-rmiz-modal-overlay='hidden'] {
  background-color: rgb(28, 25, 23, 0);
}
[data-rmiz-modal-overlay='visible'] {
  background-color: rgb(28, 25, 23, 1);
}
