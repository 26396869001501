:root {
  --spectrum-red-dark: hsl(0 100% 40%);
  --spectrum-red-desaturated: hsl(0 100% 40% / 0.12);
  --spectrum-red-light: hsl(0 100% 70%);
  --spectrum-red-saturated: hsl(0 100% 56%);

  --spectrum-orange-dark: hsl(15 100% 40%);
  --spectrum-orange-desaturated: hsl(15 100% 50% / 0.12);
  --spectrum-orange-light: hsl(15 100% 70%);
  --spectrum-orange-saturated: hsl(15 100% 55%);

  --spectrum-amber-dark: hsl(30 100% 40%);
  --spectrum-amber-desaturated: hsl(30 100% 50% / 0.12);
  --spectrum-amber-light: hsl(30 100% 70%);
  --spectrum-amber-saturated: hsl(30 100% 52%);

  --spectrum-yellow-dark: hsl(45 100% 35%);
  --spectrum-yellow-desaturated: hsl(45 100% 50% / 0.16);
  --spectrum-yellow-light: hsl(45 100% 70%);
  --spectrum-yellow-saturated: hsl(45 100% 54%);

  --spectrum-lime-dark: hsl(90 80% 35%);
  --spectrum-lime-desaturated: hsl(91 96% 46% / 0.16);
  --spectrum-lime-light: hsl(90 80% 70%);
  --spectrum-lime-saturated: hsl(90 83% 56%);

  --spectrum-green-dark: hsl(120 48% 40%);
  --spectrum-green-desaturated: hsl(120 94% 34% / 0.12);
  --spectrum-green-light: hsl(120 48% 70%);
  --spectrum-green-saturated: hsl(120 59% 51%);

  --spectrum-emerald-dark: hsl(150 48% 40%);
  --spectrum-emerald-desaturated: hsl(149 94% 34% / 0.12);
  --spectrum-emerald-light: hsl(150 48% 70%);
  --spectrum-emerald-saturated: hsl(150 79% 52%);

  --spectrum-teal-dark: hsl(180 80% 32%);
  --spectrum-teal-desaturated: hsl(180 96% 46% / 0.12);
  --spectrum-teal-light: hsl(180 80% 60%);
  --spectrum-teal-saturated: hsl(180 83% 46%);

  --spectrum-cyan-dark: hsl(195 96% 40%);
  --spectrum-cyan-desaturated: hsl(195 97% 50% / 0.12);
  --spectrum-cyan-light: hsl(195 96% 70%);
  --spectrum-cyan-saturated: hsl(195 96% 49%);

  --spectrum-sky-dark: hsl(210 97% 40%);
  --spectrum-sky-desaturated: hsl(210 100% 50% / 0.12);
  --spectrum-sky-light: hsl(210 97% 75%);
  --spectrum-sky-saturated: hsl(210 100% 57%);

  --spectrum-blue-dark: hsl(225 96% 40%);
  --spectrum-blue-desaturated: hsl(225 97% 50% / 0.16);
  --spectrum-blue-light: hsl(225 96% 80%);
  --spectrum-blue-saturated: hsl(225 100% 56%);

  --spectrum-indigo-dark: hsl(249 98% 40%);
  --spectrum-indigo-desaturated: hsl(248 100% 51% / 0.16);
  --spectrum-indigo-light: hsl(249 98% 80%);
  --spectrum-indigo-saturated: hsl(249 100% 63%);

  --spectrum-violet-dark: hsl(270 80% 40%);
  --spectrum-violet-desaturated: hsl(269 96% 46% / 0.16);
  --spectrum-violet-light: hsl(270 80% 75%);
  --spectrum-violet-saturated: hsl(270 91% 53%);

  --spectrum-purple-dark: hsl(285 80% 40%);
  --spectrum-purple-desaturated: hsl(275 100% 50% / 0.12);
  --spectrum-purple-light: hsl(285 80% 70%);
  --spectrum-purple-saturated: hsl(285 91% 53%);

  --spectrum-fuchsia-dark: hsl(315 89% 40%);
  --spectrum-fuchsia-desaturated: hsl(315 100% 52% / 0.12);
  --spectrum-fuchsia-light: hsl(315 89% 70%);
  --spectrum-fuchsia-saturated: hsl(315 92% 51%);

  --spectrum-pink-dark: hsl(330 80% 40%);
  --spectrum-pink-desaturated: hsl(315 96% 46% / 0.12);
  --spectrum-pink-light: hsl(330 80% 70%);
  --spectrum-pink-saturated: hsl(330 91% 53%);
}
